import { decodingContent } from '../../../utils/decodingContent';

// types
import type { TitleProps } from './title.types';

// components
import { InlineEdit } from '../../ContentCreator/InlineEdit';

export const TitleFormats = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
};

/**
 * Set Title with given tag and the regarding next lower tag
 *
 * @param title Title text
 * @param subtitle  Subtitle text
 * @param format Title tag format
 * @param pageHeadline Is the it a pageHeadline or not, this will change the wrapperClass
 * @param props Can contain a className which replaces the wrapper default className
 * @returns {*}
 * @constructor
 */

export function Title({
  title,
  subtitle,
  Format,
  pageHeadline = false,
  className,
  formatClassName,
  hasIntroText,
  titlePreviewId,
  subtitlePreviewId,
  isSectionReference = false,
  ...props
}: Readonly<TitleProps>) {
  // generate classNames depending on pageHeadline
  const wrapperClass = pageHeadline ? 'grid-container c-page-headline' : 'separate-title';
  const introTextClass = hasIntroText ? 'has-introtext' : '';
  const parsedTitle = decodingContent(title);
  const parsedSubtitle = decodingContent(subtitle);

  if (!(parsedTitle || parsedSubtitle)) return null;

  return (
    <div className={`title--block ${className ?? wrapperClass} ${introTextClass}`}>
      <Format {...props} className={formatClassName}>
        <b>
          <InlineEdit previewId={titlePreviewId} isSectionReference={isSectionReference}>
            {parsedTitle}{' '}
          </InlineEdit>
        </b>
        <InlineEdit previewId={subtitlePreviewId} isSectionReference={isSectionReference}>
          {parsedSubtitle}
        </InlineEdit>
      </Format>
    </div>
  );
}
